import { FunctionComponent, useEffect } from 'react';
import { PageContent } from '../../Components/PageContent/PageContent';
import { LinkUtils } from '@indece-common/ic-ui-lib-react';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';


export const LoginSuccessPage: FunctionComponent = ( ) =>
{
    const auth = useAuth();
    const navigate = useNavigate();


    useEffect( ( ) =>
    {
        if ( auth.isLoading || !auth.userData )
        {
            return;
        }

        try
        {
            const afterLoginURL = window.sessionStorage.getItem('kulturbunt-afterloginpath');

            if ( afterLoginURL )
            {
                window.sessionStorage.removeItem('kulturbunt-afterloginpath');

                navigate(afterLoginURL);
            }
            else
            {
                navigate(LinkUtils.make('admin', 'home'));
            }
        }
        catch ( err )
        {
            console.error(`Error navigating to after-login path: ${(err as Error).message}`, err);

            navigate(LinkUtils.make('admin', 'home'));
        }
    }, [auth, navigate]);


    return (
        <div className='LoginSuccessPage'>
            <PageContent>
            </PageContent>
        </div>
    );
};
