import React from 'react';
import { Form, Formik } from 'formik';
import { sleep } from 'ts-delay';
import { InputMarkdown } from '../../Components/Input/InputMarkdown';
import { InputSelectFile } from '../../Components/Input/InputSelectFile';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { LocationService } from '../../Services/LocationService';
import { MetadataService } from '../../Services/MetadataService';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminAddLocationPage.css';


export interface AdminAddLocationPageProps extends RouteComponentProps
{
}


interface AdminAddLocationPageFormData
{
    title:              string;
    abstract:           string;
    preview_image_uid:  string | null;
    description:        string;
}


interface AdminAddLocationPageState
{
    loading:    boolean;
    success:    string | null;
    error:      Error | null;
}


class $AdminAddLocationPage extends React.Component<AdminAddLocationPageProps, AdminAddLocationPageState>
{
    private readonly INITIAL_FORMVALUES: AdminAddLocationPageFormData = {
        title:              '',
        abstract:           '',
        preview_image_uid:  null,
        description:        ''
    };
    private readonly _locationService:  LocationService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminAddLocationPageProps )
    {
        super(props);

        this.state = {
            loading:    false,
            success:    null,
            error:      null
        };

        this._locationService = LocationService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._submit    = this._submit.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _submit ( values: AdminAddLocationPageFormData ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const title = values.title.trim();
            const abstract = values.abstract.trim();
            const preview_image_uid = values.preview_image_uid || null;
            const description = values.description.trim();

            const locationID = await this._locationService.addLocation({
                title,
                abstract,
                preview_image_uid,
                image_uids: [], // TODO
                description
            });

            this.setState({
                success:    'Die Location wurde erfolgreich angelegt',
                loading:    false
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('location', locationID));
        }
        catch ( err )
        {
            console.error(`Error adding location: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public componentDidMount ( ): void
    {
        this._metadataService.setTitle('Neue Location');
    }


    public render ( )
    {
        return (
            <div className='AdminAddLocationPage'>
                <PageContent noHeader={true}>
                    <h1>Neue Location anlegen</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <Formik
                            initialValues={this.INITIAL_FORMVALUES}
                            onSubmit={this._submit}>       
                            <Form>
                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label='Name'
                                            name='title'
                                            required={true}
                                        />
                                    </IcGridItem>
                                    
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label='Kurzbeschreibung'
                                            name='abstract'
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <InputSelectFile
                                            label='Vorschaubild'
                                            name='preview_image_uid'
                                        />
                                    </IcGridItem>
                                    
                                    <IcGridItem s={12}>
                                        <InputMarkdown
                                            label='Beschreibung'
                                            name='description'
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        Abbrechen
                                    </IcButton>

                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        Anlegen
                                    </IcButton>
                                </IcFloatRow>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminAddLocationPage = withRouter($AdminAddLocationPage);
