import React from 'react';
import DayJS from 'dayjs';
import { Form, Formik } from 'formik';
import { InputSelectFile } from '../../Components/Input/InputSelectFile';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { EventService, EventV1, EventV1BookingType } from '../../Services/EventService';
import { MetadataService } from '../../Services/MetadataService';
import { InputMarkdown } from '../../Components/Input/InputMarkdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { InputSelectEventLocation } from '../../Components/Input/InputSelectEventLocation';
import { ModalService } from '../../Services/ModalService';
import { IcButton, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputDateTime, IcInputSelect, IcInputSelectItem, IcInputText, IcInputTextarea, IcSpinner, IcSpinnerSize, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminEditEventPage.css';


export interface AdminEditEventPageRouteParams
{
    eventID: string;
}


export interface AdminEditEventPageProps extends RouteComponentProps<AdminEditEventPageRouteParams>
{
}


interface AdminEditEventPageFormData
{
    title:                      string;
    abstract:                   string;
    description:                string;
    price:                      string;
    eventlocation_id:           string;
    email_confirmation_note:    string;
    email_rejection_note:       string;
    preview_image_uid:          string | null;
    image_uids:                 Array<string>;
}


interface AdminEditEventPageFormDataDate
{
    datetime_start:         string;
    datetime_doors:         string;
    datetime_end:           string;
    reservation_enabled:    string;
    booking_type:           string;
    booking_url:            string;
}


interface AdminEditEventPageState
{
    initialFormValues:          AdminEditEventPageFormData;
    initialFormValuesAddDate:   AdminEditEventPageFormDataDate;
    initialFormValuesEditDate:  AdminEditEventPageFormDataDate;
    event:                      EventV1 | null;
    showAddDate:                boolean;
    showEditDateID:             number | null;
    loading:                    boolean;
    success:                    string | null;
    error:                      Error | null;
}


class $AdminEditEventPage extends React.Component<AdminEditEventPageProps, AdminEditEventPageState>
{
    private readonly OPTIONS_RESERVATIONENABLED: Array<IcInputSelectItem> = [
        {
            label:  'Nein',
            value:  'false'
        },
        {
            label:  'Ja',
            value:  'true'
        }
    ];
    private readonly OPTIONS_BOOKINGTYPE: Array<IcInputSelectItem> = [
        {
            label:  'Nein',
            value:  'none'
        },
        {
            label:  'Ja (München-Ticket)',
            value:  EventV1BookingType.MuenchenTicket
        }
    ];
    private readonly _eventService:  EventService;
    private readonly _modalService:  ModalService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminEditEventPageProps )
    {
        super(props);

        this.state = {
            initialFormValues: {
                title:                      '',
                abstract:                   '',
                description:                '',
                price:                      '',
                eventlocation_id:           '',
                email_confirmation_note:    '',
                email_rejection_note:       '',
                preview_image_uid:          null,
                image_uids:                 []
            },
            initialFormValuesAddDate: {
                datetime_start:         '',
                datetime_doors:         '',
                datetime_end:           '',
                reservation_enabled:    '',
                booking_type:           '',
                booking_url:            ''
            },
            initialFormValuesEditDate: {
                datetime_start:         '',
                datetime_doors:         '',
                datetime_end:           '',
                reservation_enabled:    '',
                booking_type:           '',
                booking_url:            ''
            },
            event:          null,
            showAddDate:    false,
            showEditDateID: null,
            loading:        true,
            success:        null,
            error:          null
        };

        this._eventService = EventService.getInstance();
        this._modalService = ModalService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._submit            = this._submit.bind(this);
        this._submitAddDate     = this._submitAddDate.bind(this);
        this._submitEditDate    = this._submitEditDate.bind(this);
        this._showAddDate       = this._showAddDate.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const eventID = parseInt(this.props.router.params.eventID, 10);
            const event = await this._eventService.getEvent(eventID);

            this.setState({
                initialFormValues: {
                    title:                      event.title,
                    abstract:                   event.abstract,
                    description:                event.description,
                    price:                      event.price || '',
                    eventlocation_id:           event.location.id + '',
                    email_confirmation_note:    event.email_confirmation_note || '',
                    email_rejection_note:       event.email_rejection_note || '',
                    preview_image_uid:          event.preview_image_uid,
                    image_uids:                 event.image_uids
                },
                event,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _submit ( values: AdminEditEventPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.event )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const title = values.title.trim();
            const abstract = values.abstract.trim();
            const preview_image_uid = values.preview_image_uid || null;
            const description = values.description.trim();

            await this._eventService.updateEvent(
                this.state.event.id,
                {
                    title,
                    abstract,
                    description,
                    price:                      values.price.trim() || null,
                    email_confirmation_note:    values.email_confirmation_note.trim() || null,
                    email_rejection_note:       values.email_rejection_note.trim() || null,
                    preview_image_uid,
                    image_uids:                 values.image_uids,
                    eventlocation_id:           parseInt(values.eventlocation_id)
                }
            );

            this.setState({
                success:    'Die Veranstaltung wurde erfolgreich aktualisiert',
                loading:    false
            });

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error updating event: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _showAddDate ( ): void
    {
        this.setState({
            initialFormValuesAddDate: {
                datetime_start:         '',
                datetime_doors:         '',
                datetime_end:           '',
                reservation_enabled:    '',
                booking_type:           '',
                booking_url:            ''
            },
            showAddDate:    true,
            showEditDateID: null
        });
    }
    
    
    private _showEditDate ( dateID: number ): void
    {
        if ( ! this.state.event )
        {
            return;
        }

        const eventDate = this.state.event.dates.find( o => o.id === dateID );
        if ( ! eventDate )
        {
            return;
        }

        let bookingType = '';

        if ( eventDate.booking_enabled && eventDate.booking_type === EventV1BookingType.MuenchenTicket )
        {
            bookingType = EventV1BookingType.MuenchenTicket;
        }

        this.setState({
            initialFormValuesEditDate: {
                datetime_start:         DayJS(eventDate.datetime_start).format('YYYY-MM-DD HH:mm'),
                datetime_doors:         eventDate.datetime_doors ? DayJS(eventDate.datetime_doors).format('YYYY-MM-DD HH:mm') : '',
                datetime_end:           eventDate.datetime_end ? DayJS(eventDate.datetime_end).format('YYYY-MM-DD HH:mm') : '',
                reservation_enabled:    eventDate.reservation_enabled ? 'true' : 'false',
                booking_type:           bookingType,
                booking_url:            eventDate.booking_url || ''
            },
            showAddDate:    false,
            showEditDateID: dateID
        });
    }


    private async _submitAddDate ( values: AdminEditEventPageFormDataDate ): Promise<void>
    {
        if ( this.state.loading || !this.state.event )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            let booking_enabled: boolean = false;
            let booking_type: EventV1BookingType | null = null;

            switch ( values.booking_type )
            {
                case EventV1BookingType.MuenchenTicket:
                    booking_enabled = true;
                    booking_type = EventV1BookingType.MuenchenTicket;
                    break;
            }
            
            await this._eventService.addEventDate(
                this.state.event.id,
                {
                    datetime_start: DayJS(values.datetime_start).format(),
                    datetime_doors: values.datetime_doors ? DayJS(values.datetime_doors).format() : null,
                    datetime_end: values.datetime_end ? DayJS(values.datetime_end).format() : null,
                    reservation_enabled: values.reservation_enabled === 'true',
                    booking_enabled,
                    booking_type,
                    booking_url: values.booking_url || null
                }
            );

            this.setState({
                success:        'Der Termin wurde erfolgreich hinzugefügt',
                loading:        false,
                showAddDate:    false
            });

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error adding event date: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _submitEditDate ( values: AdminEditEventPageFormDataDate ): Promise<void>
    {
        if ( this.state.loading || !this.state.event || !this.state.showEditDateID )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            let booking_enabled: boolean = false;
            let booking_type: EventV1BookingType | null = null;

            switch ( values.booking_type )
            {
                case EventV1BookingType.MuenchenTicket:
                    booking_enabled = true;
                    booking_type = EventV1BookingType.MuenchenTicket;
                    break;
            }

            await this._eventService.updateEventDate(
                this.state.event.id,
                this.state.showEditDateID,
                {
                    datetime_start: DayJS(values.datetime_start).format(),
                    datetime_doors: values.datetime_doors ? DayJS(values.datetime_doors).format() : null,
                    datetime_end: values.datetime_end ? DayJS(values.datetime_end).format() : null,
                    reservation_enabled: values.reservation_enabled === 'true',
                    booking_enabled,
                    booking_type,
                    booking_url: values.booking_url || null
                }
            );

            this.setState({
                success:        'Der Termin wurde erfolgreich aktualisiert',
                loading:        false,
                showEditDateID: null
            });

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error updating event date: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _showCancelDate ( eventDateID: number ): void
    {
        if ( ! this.state.event )
        {
            return;
        }

        this._modalService.showCancelEventDate({
            eventID: this.state.event.id,
            eventDateID,
            onFinish: async ( ) =>
            {
                await this._load();
            }
        });
    }


    private _showDeleteDate ( eventDateID: number ): void
    {
        if ( ! this.state.event )
        {
            return;
        }

        this._modalService.showDeleteEventDate({
            eventID: this.state.event.id,
            eventDateID,
            onFinish: async ( ) =>
            {
                await this._load();
            }
        });
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Veranstaltung bearbeiten');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminEditEventPage'>
                <PageContent noHeader={true}>
                    <h1>Veranstaltung bearbeiten</h1>

                    <IcErrorBox error={this.state.error} />

                    <SuccessBox message={this.state.success} />

                    <Formik
                        initialValues={this.state.initialFormValues}
                        enableReinitialize={true}
                        onSubmit={this._submit}>       
                        <Form>
                            <IcGridRow padding={IcGridPadding.Small}>
                                <IcGridItem s={12}>
                                    <IcInputText
                                        label='Name'
                                        name='title'
                                        required={true}
                                    />
                                </IcGridItem>
                                
                                <IcGridItem s={12}>
                                    <IcInputText
                                        label='Kurzbeschreibung'
                                        name='abstract'
                                    />
                                </IcGridItem>
                                
                                <IcGridItem s={12}>
                                    <InputSelectEventLocation
                                        label='Location'
                                        name='eventlocation_id'
                                        required={true}
                                    />
                                </IcGridItem>
                                
                                <IcGridItem s={12}>
                                    <InputSelectFile
                                        label='Vorschaubild'
                                        name='preview_image_uid'
                                    />
                                </IcGridItem>
                                
                                <IcGridItem s={12}>
                                    <InputMarkdown
                                        label='Beschreibung'
                                        name='description'
                                        required={true}
                                    />
                                </IcGridItem>
                                
                                <IcGridItem s={12}>
                                    <IcInputText
                                        label='Preis'
                                        name='price'
                                    />
                                </IcGridItem>
                                
                                <IcGridItem s={12}>
                                    <IcInputTextarea
                                        label='Anmerkung für Bestätigungs-Email'
                                        name='email_confirmation_note'
                                    />
                                </IcGridItem>
                            </IcGridRow>

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton type='submit'>
                                    Speichern
                                </IcButton>
                            </IcFloatRow>
                        </Form>
                    </Formik>

                    <h1>Termine</h1>

                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            type='button'
                            onClick={this._showAddDate}>
                            <FontAwesomeIcon icon={faPlus} />
                            Neuer Termin
                        </IcButton>
                    </IcFloatRow>

                    {this.state.showAddDate ?
                        <Formik
                            initialValues={this.state.initialFormValuesAddDate}
                            onSubmit={this._submitAddDate}
                            enableReinitialize={true}>
                            { ( formikProps ) => (
                                <Form>
                                    <div className='AdminAddEventPage-date'>
                                        <div>
                                            <IcGridRow padding={IcGridPadding.Small}>
                                                <IcGridItem s={6}>
                                                    <IcInputDateTime
                                                        name='datetime_start'
                                                        label='Start'
                                                        required={true}
                                                    />
                                                </IcGridItem>

                                                <IcGridItem s={6}>
                                                    <IcInputDateTime
                                                        name='datetime_end'
                                                        label='Ende'
                                                    />
                                                </IcGridItem>

                                                <IcGridItem s={4}>
                                                    <IcInputDateTime
                                                        name='datetime_doors'
                                                        label='Einlass'
                                                    />
                                                </IcGridItem>
                                                
                                                <IcGridItem s={4}>
                                                    <IcInputSelect
                                                        name='reservation_enabled'
                                                        label='Reservierung möglich'
                                                        options={this.OPTIONS_RESERVATIONENABLED}
                                                        required={true}
                                                    />
                                                </IcGridItem>
                                                
                                                <IcGridItem s={4}>
                                                    <IcInputSelect
                                                        name='booking_type'
                                                        label='Buchung möglich'
                                                        options={this.OPTIONS_BOOKINGTYPE}
                                                        required={true}
                                                    />
                                                </IcGridItem>

                                                {formikProps.values.booking_type === EventV1BookingType.MuenchenTicket ?
                                                    <IcGridItem s={12}>
                                                        <IcInputText
                                                            name='booking_url'
                                                            label='Link zur Buchungs-Seite'
                                                            required={true}
                                                        />
                                                    </IcGridItem>
                                                : null}
                                            </IcGridRow>
                                        </div>

                                        <IcButton type='submit'>
                                            <FontAwesomeIcon icon={faSave} />
                                            Termin speichern
                                        </IcButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    : null}

                    {this.state.event ?
                        <div className='AdminEditEventPage-dates'>
                            {this.state.event.dates.map( ( date ) => (
                                <div className='AdminEditEventPage-date' key={date.id}>
                                    {this.state.showEditDateID === date.id ?
                                        <Formik
                                            initialValues={this.state.initialFormValuesEditDate}
                                            onSubmit={this._submitEditDate}
                                            enableReinitialize={true}>
                                            { ( formikProps ) => (
                                                <Form>
                                                    <div className='AdminAddEventPage-date'>
                                                        <div>
                                                            <IcGridRow padding={IcGridPadding.Small}>
                                                                <IcGridItem s={6}>
                                                                    <IcInputDateTime
                                                                        name='datetime_start'
                                                                        label='Start'
                                                                        required={true}
                                                                    />
                                                                </IcGridItem>
                
                                                                <IcGridItem s={6}>
                                                                    <IcInputDateTime
                                                                        name='datetime_end'
                                                                        label='Ende'
                                                                    />
                                                                </IcGridItem>
                
                                                                <IcGridItem s={4}>
                                                                    <IcInputDateTime
                                                                        name='datetime_doors'
                                                                        label='Einlass'
                                                                    />
                                                                </IcGridItem>
                                                                
                                                                <IcGridItem s={4}>
                                                                    <IcInputSelect
                                                                        name='reservation_enabled'
                                                                        label='Reservierung möglich'
                                                                        options={this.OPTIONS_RESERVATIONENABLED}
                                                                        required={true}
                                                                    />
                                                                </IcGridItem>
                                                                
                                                                <IcGridItem s={4}>
                                                                    <IcInputSelect
                                                                        name='booking_type'
                                                                        label='Buchung möglich'
                                                                        options={this.OPTIONS_BOOKINGTYPE}
                                                                        required={true}
                                                                    />
                                                                </IcGridItem>
                    
                                                                {formikProps.values.booking_type === EventV1BookingType.MuenchenTicket ?
                                                                    <IcGridItem s={12}>
                                                                        <IcInputText
                                                                            name='booking_url'
                                                                            label='Link zur Buchungs-Seite'
                                                                            required={true}
                                                                        />
                                                                    </IcGridItem>
                                                                : null}
                                                            </IcGridRow>
                                                        </div>
                
                                                        <IcButton type='submit'>
                                                            <FontAwesomeIcon icon={faSave} />
                                                            Termin speichern
                                                        </IcButton>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    :
                                        <>
                                            <div className='AdminEditEventPage-date-info'>
                                                <div className='AdminEditEventPage-date-date'>
                                                    {DayJS(date.datetime_start).format('DD.MM.YYYY')}
                                                </div>

                                                <div className='AdminEditEventPage-date-time'>
                                                    {DayJS(date.datetime_start).format('HH:mm')} Uhr
                                                </div>

                                                <div className='AdminEditEventPage-date-status'>
                                                    {!date.full && !date.canceled && DayJS(date.datetime_start).isAfter(DayJS()) && date.reservation_enabled ?
                                                        <>Reservierungen aktiv</>
                                                    : null}

                                                    {!date.full && !date.canceled && DayJS(date.datetime_start).isAfter(DayJS()) && date.booking_enabled ?
                                                        <>Ticketbuchungen aktiv</>
                                                    : null}
                                                    
                                                    {!date.full && !date.canceled && DayJS(date.datetime_start).isBefore(DayJS()) ?
                                                        <>Beendet</>
                                                    : null}
                                                    
                                                    {date.full && !date.canceled ?
                                                        <>Ausgebucht</>
                                                    : null}
                                                    
                                                    {date.canceled ?
                                                        <>Abgesagt</>
                                                    : null}
                                                </div>

                                                <div className='AdminEditEventPage-date-actions'>
                                                    {!date.canceled ?
                                                        <IcButton onClick={ ( ) => this._showEditDate(date.id) }>
                                                            Bearbeiten
                                                        </IcButton>
                                                    : null}
                                                    
                                                    {!date.canceled ?
                                                        <IcButton onClick={ ( ) => this._showCancelDate(date.id) }>
                                                            Absagen
                                                        </IcButton>
                                                    : null}

                                                    <IcButton onClick={ ( ) => this._showDeleteDate(date.id) }>
                                                        Löschen
                                                    </IcButton>
                                                </div>
                                            </div>

                                            <div className='AdminEditEventPage-date-details'>
                                                {date.datetime_doors ?
                                                    <div className='AdminEditEventPage-date-details-row'>
                                                        Einlass: {DayJS(date.datetime_doors).format('HH:mm')} Uhr
                                                    </div>
                                                : null}
                                                
                                                {date.datetime_end ?
                                                    <div className='AdminEditEventPage-date-details-row'>
                                                        Ende: {DayJS(date.datetime_end).format('HH:mm')} Uhr
                                                    </div>
                                                : null}
                                            </div>
                                        </>
                                    }
                                </div>
                            ))}
                        </div>
                    : null}

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminEditEventPage = withRouter($AdminEditEventPage);
